var ko = require("knockout");
var BrandingHelpers = require("./BrandingHelpers");
var Browser = require("./BrowserControl");
var BrandingLivePreviewFactory = require("./BrandingLivePreviewFactory");

var w = window;

function LightboxTemplate(params)
{
    
    var _this = this;

    var _brandingLivePreview = null;
    

    
    var _serverData = params.serverData;
    

    
    var _staticTenantBranding = _serverData.staticTenantBranding;
    var _livePreviewAllowedOrigins = _serverData.arrLivePreviewOrigins;
    

    
    _this.showHeader = ko.observable(false);
    _this.headerLogo = ko.observable();
    

    
    _this.dispose = function ()
    {
        if (_brandingLivePreview)
        {
            _brandingLivePreview.removeListener(_initializeTemplate);
        }
    };
    

    
    function _initializeTemplate(staticTenantBranding)
    {
        var tenantBranding = BrandingHelpers.loadTenantBranding(staticTenantBranding);
        var layoutTemplateConfig = BrandingHelpers.getLayoutTemplateConfig(tenantBranding);

        _this.showHeader(layoutTemplateConfig.showHeader);
        _this.headerLogo(tenantBranding.HeaderLogo);
    }

    (function _initialize()
    {
        _initializeTemplate(_staticTenantBranding);

        if (_livePreviewAllowedOrigins)
        {
            _brandingLivePreview = BrandingLivePreviewFactory.getInstance(_livePreviewAllowedOrigins);
            _brandingLivePreview.addListener(_initializeTemplate);
        }
    })();
    
}

ko.components.register("lightbox-template",
    {
        viewModel: LightboxTemplate,
        template: require("html/Shared/Templates/ConvergedLightboxTemplateHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = LightboxTemplate;