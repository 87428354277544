var ko = require("knockout");
var BrandingHelpers = require("../Core/BrandingHelpers");
var Browser = require("./BrowserControl");
var ComponentEvent = require("./ComponentEvent");
var Constants = require("./Constants");

var w = window;
var AgreementType = Constants.AgreementType;

function FooterControl(params)
{
    
    var _this = this;
    

    
    var _serverData = params.serverData;
    var _showLogo = params.showLogo;
    var _showLinks = params.showLinks;
    var _hideFooter = params.hideFooter;
    var _debugDetails = params.debugDetails;
    var _showDebugDetails = params.showDebugDetails;
    var _hasDarkBackground = params.hasDarkBackground;
    var _useDefaultBackground = params.useDefaultBackground;
    

    
    var _strings = _serverData.str;
    var _isHosted = _serverData.fIsHosted;
    var _isChinaDc = _serverData.fIsChinaDc;
    var _termsLink = _serverData.urlFooterTOU || _serverData.urlHostedTOULink;
    var _privacyLink = _serverData.urlFooterPrivacy || _serverData.urlHostedPrivacyLink;
    var _impressumLink = _serverData.urlImpressum;
    var _icpLink = _serverData.urlGallatinIcp;
    var _staticTenantBranding = _serverData.staticTenantBranding;
    

    
    _this.onAgreementClick = ComponentEvent.create();
    _this.onShowDebugDetails = ComponentEvent.create();
    

    
    _this.showDebugDetails = ko.observable(!!_showDebugDetails);
    _this.focusMoreInfo = ko.observable(false).extend({ notify: "always" });

    _this.showLogo = _showLogo && !_isChinaDc;
    _this.showLinks = _showLinks;
    _this.hideFooter = _hideFooter;
    _this.showIcpLicense = _isChinaDc;
    _this.debugDetails = _debugDetails;
    _this.termsLink = _termsLink;
    _this.privacyLink = _privacyLink;
    _this.impressumLink = _impressumLink;
    _this.icpLink = _icpLink;
    _this.hasDarkBackground = _hasDarkBackground;
    _this.useDefaultBackground = _useDefaultBackground;
    _this.showFooter = true;
    _this.hideTOU = false;
    _this.termsText = null;
    _this.hidePrivacy = false;
    _this.privacyText = null;
    

    
    _this.privacyLink_onClick = function ()
    {
        handleLegalLink(AgreementType.Privacy, _this.privacyLink);
    };

    _this.termsLink_onClick = function ()
    {
        handleLegalLink(AgreementType.Tou, _this.termsLink);
    };

    _this.impressumLink_onClick = function ()
    {
        handleLegalLink(AgreementType.Impressum, _this.impressumLink);
    };

    _this.moreInfo_onClick = function ()
    {
        _this.setDebugDetailsState(!_this.showDebugDetails());
        _this.onShowDebugDetails();

        if (!_this.showDebugDetails())
        {
            _this.focusMoreInfo(true);
        }
    };

    _this.setDebugDetailsState = function (state)
    {
        _this.showDebugDetails(state);
    };

    _this.focusMoreInfoLink = function ()
    {
        _this.focusMoreInfo(true);
    };
    

    
    function handleLegalLink(agreementType, url)
    {
        if (_isHosted && !_isChinaDc)
        {
            _this.onAgreementClick(agreementType);
        }
        else
        {
            w.open(url, "_blank");
        }
    }

    (function _initialize()
    {
        var tenantBranding = BrandingHelpers.loadTenantBranding(_staticTenantBranding);
        var layoutTemplateConfig = BrandingHelpers.getLayoutTemplateConfig(tenantBranding);

        _this.showFooter = layoutTemplateConfig.showFooter;
        _this.hideTOU = layoutTemplateConfig.hideTOU;
        _this.hidePrivacy = layoutTemplateConfig.hidePrivacy;
        _this.termsText = tenantBranding.FooterTOUText || _strings["MOBILE_STR_Footer_Terms"];
        _this.privacyText = tenantBranding.FooterPrivacyText || _strings["MOBILE_STR_Footer_Privacy"];
    })();
    
}

ko.components.register("footer-control",
    {
        viewModel: FooterControl,
        template: require("html/Shared/Controls/FooterControlHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = FooterControl;