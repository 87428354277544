var ko = require("knockout");
var Helpers = require("./Helpers");
var Browser = require("./BrowserControl");
var Constants = require("../Core/Constants");

var requireDefaultBackgroundImage = require.context("images/Backgrounds", false, /^.+?\.jpg|svg$/);
var requireAppBackgroundImage = require.context("images/AppBackgrounds", false, /^.+?\.jpg$/);
var requireAppBackgroundLogo = require.context("images/AppLogos", false, /^.+?\.png$/);

var BrowserHelper = Browser.Helper;
var StringHelpers = Helpers.String;
var LayoutTemplateType = Constants.LayoutTemplateType;
var LayoutTemplateHorizontalPosition = Constants.LayoutTemplateHorizontalPosition;

var BrandingHelpers =
{
    loadTenantBranding: function (brandingToUse)
    {
        var brandingObject = {};

        if (brandingToUse)
        {
            var brandingProperties =
                [
                    "BoilerPlateText",
                    "UserIdLabel",
                    "TileLogo",
                    "TileDarkLogo",
                    "BannerLogo",
                    "BackgroundColor",
                    "Illustration",
                    "KeepMeSignedInDisabled",
                    "UseTransparentLightBox",
                    "CustomCss",
                    "LayoutTemplateConfig",
                    "HeaderLogo",
                    "AccessRecoveryLink",
                    "FooterTOULink",
                    "FooterTOUText",
                    "FooterPrivacyLink",
                    "FooterPrivacyText"
                ];

            
            
            var fallbackBranding = brandingToUse[0] || {};
            var preferredBranding = brandingToUse[1] || {};

            
            ko.utils.arrayForEach(
                brandingProperties,
                function (brandingProperty)
                {
                    
                    brandingObject[brandingProperty] = preferredBranding[brandingProperty] || fallbackBranding[brandingProperty] || "";
                }
            );

            
            if (!brandingObject.TileDarkLogo)
            {
                brandingObject.TileDarkLogo = brandingObject.TileLogo;
            }
        }

        return brandingObject;
    },

    getPageBranding: function (tenantBranding, appBranding, defaultImage)
    {
        var branding = { useDefaultBackground: false };

        if (tenantBranding)
        {
            branding.bannerLogoUrl = tenantBranding.BannerLogo;
        }

        if (tenantBranding && (tenantBranding.BackgroundColor || tenantBranding.Illustration))
        {
            
            branding.color = tenantBranding.BackgroundColor;
            branding.backgroundImageUrl = tenantBranding.Illustration;
            branding.useTransparentLightBox = tenantBranding.UseTransparentLightBox;
            branding.useImageMask = true;
        }
        else if (appBranding
            && (appBranding.backgroundImageIndex >= 0 || appBranding.backgroundLogoIndex >= 0 || appBranding.backgroundColor || appBranding.friendlyAppName))
        {
            
            if (appBranding.backgroundImageIndex >= 0)
            {
                branding.backgroundImageUrl = requireAppBackgroundImage(StringHelpers.format("./{0}.jpg", appBranding.backgroundImageIndex));

                if (BrowserHelper.isStyleSupported("backgroundSize"))
                {
                    
                    branding.smallImageUrl = requireAppBackgroundImage(StringHelpers.format("./{0}-small.jpg", appBranding.backgroundImageIndex));
                }
            }

            if (appBranding.backgroundLogoIndex >= 0)
            {
                branding.backgroundLogoUrl = requireAppBackgroundLogo(StringHelpers.format("./{0}.png", appBranding.backgroundLogoIndex));
            }

            branding.color = appBranding.backgroundColor;
            branding.friendlyAppName = appBranding.friendlyAppName;
        }
        else if (appBranding && appBranding.urlLegacyBackgroundLogo)
        {
            
            
            branding.backgroundLogoUrl = appBranding.urlLegacyBackgroundLogo;
        }
        else if (defaultImage >= 0)
        {
            var useSvg = BrowserHelper.isSvgImgSupported();

            branding.backgroundImageUrl = requireDefaultBackgroundImage(
                StringHelpers.format(
                    "./{0}.{1}",
                    defaultImage,
                    useSvg ? "svg" : "jpg"));

            if (!useSvg && BrowserHelper.isStyleSupported("backgroundSize"))
            {
                
                branding.smallImageUrl = requireDefaultBackgroundImage(StringHelpers.format("./{0}-small.jpg", defaultImage));
            }

            branding.useDefaultBackground = true;
        }

        return branding;
    },

    getMergedBranding: function (staticTenantBranding, dynamicTenantBranding, isGlobalTenant)
    {
        var mergedBranding;

        if (isGlobalTenant)
        {
            
            mergedBranding = dynamicTenantBranding;
        }
        else
        {
            
            mergedBranding = staticTenantBranding;
            mergedBranding.BannerLogo = dynamicTenantBranding && dynamicTenantBranding.BannerLogo;
            mergedBranding.BoilerPlateText = dynamicTenantBranding && dynamicTenantBranding.BoilerPlateText;
            mergedBranding.KeepMeSignedInDisabled = dynamicTenantBranding && dynamicTenantBranding.KeepMeSignedInDisabled;

            if (dynamicTenantBranding
                && staticTenantBranding.CustomCss !== dynamicTenantBranding.CustomCss)
            {
                mergedBranding.CustomCss = null;
            }
        }

        return mergedBranding;
    },

    getLayoutTemplateConfig: function (tenantBranding)
    {
        var layoutTemplateConfig = tenantBranding.LayoutTemplateConfig;
        if (layoutTemplateConfig && layoutTemplateConfig !== {})
        {
            return layoutTemplateConfig;
        }

        
        var defaultLayoutTemplateConfig =
            {
                showHeader: false,
                layoutType: LayoutTemplateType.Lightbox,
                horizontalPosition: LayoutTemplateHorizontalPosition.Center,
                hideAccessRecovery: false,
                hideSignInOptions: false,
                showFooter: true,
                hideTOU: false,
                hidePrivacy: false
            };

        return defaultLayoutTemplateConfig;
    }
};

module.exports = BrandingHelpers;