var ko = require("knockout");
var Browser = require("../Core/BrowserControl");

var w = window;

function HeaderControl(params)
{
    
    var _this = this;
    

    
    var _serverData = params.serverData;
    var _title = params.title;
    var _useSubtitle = params.useSubtitle !== false;
    

    
    var _appCobranding = _serverData.oAppCobranding;
    

    
    _this.title = null;
    

    (function _initialize()
    {
        var isAppNamePresent = !!(_appCobranding && _appCobranding.friendlyAppName);
        _this.isSubtitleVisible = _useSubtitle && isAppNamePresent;
        _this.title = _title;
    })();
}

ko.components.register("header-control",
    {
        viewModel: HeaderControl,
        template: require("html/LoginPage/Controls/HeaderControlHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents)
    });

module.exports = HeaderControl;