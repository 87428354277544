var ko = require("knockout");
var Browser = require("./BrowserControl");
var ApiRequest = require("./ApiRequest");
var ApplicationInsights = require("ApplicationInsights").Microsoft.ApplicationInsights;

var w = window;

function InstrumentationViewModel(params)
{
    var _this = this;

    
    var _serverData = params.serverData;

    var _loginMode = _serverData.iLoginMode;
    var _reportPageLoadUrl = _serverData.urlReportPageLoad;
    var _checkApiCanary = _serverData.fCheckApiCanary;
    var _hostBuildNumber = _serverData.sHostBuildNumber;
    var _serverExecutionTime = _serverData.iServerExecutionTime;
    var _requestCountry = _serverData.sRequestCountry || _serverData.country;
    var _serverDetails = _serverData.serverDetails;
    var _hpgid = _serverData.hpgid;
    var _applicationInsightsEnabled = _serverData.fApplicationInsightsEnabled;
    var _applicationInsightsEnabledPercentage = _serverData.iApplicationInsightsEnabledPercentage;
    var _applicationInsightsEndpointUrl = _serverData.urlApplicationInsightsEndpoint;
    var _applicationInsightsInstrumentationKey = _serverData.sApplicationInsightsInstrumentationKey;
    var _inlineMode = _serverData.inlineMode;

    
    var _isCustomPerf = _serverData.isCustomPerf;

    
    
    var _customPageLoadCompletedTime = 0;
    var _pageLoadCompleted = false;
    var _perfDataReported = false;

    
    _this.clientMode = _loginMode; 

    
    _this.srsFailed = w.g_iSRSFailed; 
    _this.srsSuccess = w.g_sSRSSuccess; 
    _this.timeOnPage = ko.observable(null); 

    
    _this.recordSubmit = function ()
    {
        if (w.performance && w.performance.timing)
        {
            _this.timeOnPage(new Date().getTime() - w.performance.timing.loadEventEnd);
        }
    };

    _this.setPageLoadCompleted = function ()
    {
        _pageLoadCompleted = true;
        setTimeout(function ()
        {
            _sendPerfData();
        }, 0);
    };

    _this.setCustomPageLoadCompletedTime = function (ticks)
    {
        
        _customPageLoadCompletedTime = ticks || (new Date().getTime());
    };

    function _getPerformanceData()
    {
        var performance = w.performance;
        var navigator = w.navigator;
        var performanceData = {};

        if (!performance)
        {
            return null;
        }

        if (performance.navigation)
        {
            performanceData.navigation = _getPerformanceObjectData(performance.navigation);
        }

        if (performance.timing)
        {
            performanceData.timing = _getPerformanceObjectData(performance.timing);

            
            if (_customPageLoadCompletedTime > 0)
            {
                performanceData.timing.customLoadEventEnd = _customPageLoadCompletedTime;
            }
        }

        if (performance.getEntries)
        {
            performanceData.entries = ko.utils.arrayMap(
                performance.getEntries(),
                _getPerformanceObjectData);
        }

        if (navigator.connection)
        {
            
            performanceData.connection = _getPerformanceObjectData(navigator.connection);
        }

        return performanceData;
    }

    function _getPerformanceObjectData(object)
    {
        var result = {};

        
        
        if (object.toJSON)
        {
            return object.toJSON();
        }

        
        
        
        for (var property in object)
        {
            result[property] = object[property];
        }

        return result;
    }

    function _initializeApplicationInsights()
    {
        var snippet =
        {
            config:
            {
                instrumentationKey: _applicationInsightsInstrumentationKey,
                isCookieUseDisabled: true,
                isStorageUseDisabled: true,
                disableAjaxTracking: true,
                disableExceptionTracking: true,
                endpointUrl: _applicationInsightsEndpointUrl
            }
        };
        var init = new ApplicationInsights.Initialization(snippet);
        var appInsights = init.loadAppInsights();
        w.appInsights = appInsights;

        appInsights.context.addTelemetryInitializer(function (envelope)
        {
            var eventData = envelope.data.baseData;

            
            eventData.properties = eventData.properties || {};
            eventData.properties["hostBuildNumber"] = _serverDetails ? _serverDetails.ver.v.join(".") : _hostBuildNumber;
            eventData.properties["requestCountry"] = _requestCountry;
            eventData.properties["hpgid"] = _hpgid;

            eventData.measurements = eventData.measurements || {};
            eventData.measurements["enabledPercentage"] = _applicationInsightsEnabledPercentage;

            
            if (typeof eventData.url === "string")
            {
                eventData.url = eventData.url.split("?")[0];
            }

            
            var idsToRemove = [
                "ai.operation.id",
                "ai.operation.parentid",
                "ai.operation.rootid",
                "ai.session.id",
                "ai.user.accountId",
                "ai.user.authUserId",
                "ai.user.id" ];

            for (var i = 0; i < idsToRemove.length; i++)
            {
                if (envelope.tags[idsToRemove[i]])
                {
                    delete envelope.tags[idsToRemove[i]];
                }
            }
        });
    }

    function _sendPerfData()
    {
        if (_perfDataReported || !_pageLoadCompleted)
        {
            return;
        }

        
        _perfDataReported = true;
        if (_reportPageLoadUrl)
        {
            var postData =
                {
                    hostBuildNumber: _serverDetails ? _serverDetails.ver.v.join(".") : _hostBuildNumber,
                    serverExecutionTime: _serverDetails ? _serverDetails.et : _serverExecutionTime,
                    requestCountry: _requestCountry,
                    plt: 0
                };

            var performanceData = _getPerformanceData();
            if (performanceData)
            {
                postData.performanceData = performanceData;
            }

            if (_inlineMode)
            {
                postData.inlineMode = _inlineMode;
            }

            var apiRequest = new ApiRequest({ checkApiCanary: _checkApiCanary });
            apiRequest.Json(
                {
                    url: _reportPageLoadUrl
                },
                postData);
        }

        var appInsights = w.appInsights;
        if (appInsights)
        {
            var additionalProperties = {};
            var additionalMetrics = {};
            additionalMetrics["serverExecutionTime"] = _serverDetails ? _serverDetails.et : _serverExecutionTime;

            appInsights.trackPageView(null, null, additionalProperties, additionalMetrics);
        }
    }

    (function _initialize()
    {
        if (_applicationInsightsEnabled)
        {
            _initializeApplicationInsights();
        }

        if (!_isCustomPerf)
        {
            
            
            
            setTimeout(function ()
            {
                _this.setPageLoadCompleted();
            }, 100);
        }
    })();
}

ko.components.register("instrumentation-control",
    {
        viewModel: InstrumentationViewModel,
        template: require("html/Shared/Controls/InstrumentationControlHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = InstrumentationViewModel;
